import { combineReducers } from 'redux'

import appReducer from "./appReducer";
import homeReducer from "./homeReducer";
import aboutReducer from "./aboutReducer";
import blogReducer from "./blogReducer";
import teamReducer from "./teamReducer";
import singleBlogReducer from "./singleBlogReducer";
import serviceReducer from "./serviceReducer";
import portfolioReducer from "./portfolioReducer";
import singlePortfolioReducer from "./singlePortfolioReducer";
import headerReducer from "./headerReducer";
import galleryReducer from "./galleryReducer";
import pageReducer from "./pageReducer";
import productReducer from './productReducer';
import storeReducer from './storeReducer';
import etenderReducer from './etenderReducer';
import siteMapReducer from './siteMapReducer';
import searchReducer from './searchReducer';
import careerReducer from './careerReducer';
import pressReleaseReducer from './pressReleaseReducer';
import turnOverReducer from './turnOverReducer';
import outletStrengthReducer from './outletStrengthReducer';
import announcementReducer from './announcementReducer';
import hoDivisionReducer from './hoDivisionReducer';

const rootReducer = combineReducers({
    app: appReducer,
    header: headerReducer,
    home: homeReducer,
    about: aboutReducer,
    blog: blogReducer,
    team: teamReducer,
    single_blog: singleBlogReducer,
    service: serviceReducer,
    portfolio: portfolioReducer,
    single_portfolio: singlePortfolioReducer,
    gallery: galleryReducer,
    page: pageReducer,
    product: productReducer,
    store: storeReducer,
    tender: etenderReducer,
    career: careerReducer,
    sitemap: siteMapReducer,
    search_data: searchReducer,
    press_release: pressReleaseReducer,
    sales_turn_over: turnOverReducer,
    outlet_strength: outletStrengthReducer,
    announcements: announcementReducer,
    hoDivision: hoDivisionReducer
});

export default rootReducer;