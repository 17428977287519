import {LOAD_TURN_OVER_SUCCESSFUL} from "../actions/type";

const init = {};

const turnOverReducer = (state = init, action) => {
    switch (action.type) {
        case LOAD_TURN_OVER_SUCCESSFUL: {
            return action.data;
        }
        default:
            return state;
    }
};

export default turnOverReducer;