import {LOAD_GALLERY_IMAGE_SUCCESSFUL,LOAD_GALLERY_VIDEO_SUCCESSFUL,LOAD_IMAGE_CATEGORY_SUCCESSFUL,LOAD_VIDEO_CATEGORY_SUCCESSFUL} from "../actions/type";

const init = {};

const galleryCategoryReducer = (state = init, action) => {
    switch (action.type) {
        case LOAD_IMAGE_CATEGORY_SUCCESSFUL: {
            return {
                ...state,
                imageCategory:action.data
            }
        };
        case LOAD_VIDEO_CATEGORY_SUCCESSFUL: {
            return {
                ...state,
                videoCategory:action.data
            }
        };
        case LOAD_GALLERY_IMAGE_SUCCESSFUL: {
            return {
                ...state,
                imageList:action.data
            }
        };
        case LOAD_GALLERY_VIDEO_SUCCESSFUL: {
            return {
                ...state,
                videoList:action.data
            }
        };
        default:
            return state;
    }
};

export default galleryCategoryReducer;

