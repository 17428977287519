import React,{Suspense} from "react";
import { Switch } from "react-router-dom";

// Route Setting

const HomePage = React.lazy(() => import('../HomePage'));
const AboutUs = React.lazy(() => import('../AboutUs'));
const Services = React.lazy(() => import('../Services'));
const TeamPage = React.lazy(() => import('../TeamPage'));
const Portfolio = React.lazy(() => import('../Portfolio'));
const Gallery = React.lazy(() => import('../Gallery'));
const PortfolioSingle = React.lazy(() => import('../PortfolioSingle'));
const BlogLists = React.lazy(() => import('../BlogLists'));
const BlogSingle = React.lazy(() => import('../BlogSingle'));
const ContactUsPage = React.lazy(() => import('../ContactUsPage'));
const Announcements = React.lazy(() => import('../AnnouncementsDetails'));
const NewsDetails = React.lazy(() => import('../LatestNewsDetails'));
const Page = React.lazy(() => import('../Page'));
const GalleryList = React.lazy(() => import('../GalleryList'));
const PressRelease = React.lazy(() => import('../PressRelease'));
const PressReleaseDetails = React.lazy(() => import('../PressReleaseDetails'));
const SalesTurnOver = React.lazy(() => import('../SalesTurnOver'));
const GalleryVideo = React.lazy(() => import('../GalleryVideo'));
const GalleryVideoList = React.lazy(() => import('../GalleryVideoList'));
const OutletStrength = React.lazy(() => import('../OutletStrength'));
const AnnoucementList = React.lazy(() => import('../AnnoucementList'));


// Normal Route
const PrivateRoute = React.lazy(() => import('../_PrivateRoute'));
const PublicRoute = React.lazy(() => import('../_PublicRoute'));
const ProductList = React.lazy(() => import('../ProductList'));
const StoreList = React.lazy(() => import('../StoreList'));
const EtenderList = React.lazy(() => import('../EtenderList'));
const CareerList = React.lazy(() => import('../CareerList'));
const SiteMapList = React.lazy(() => import('../SiteMapList'));
const SearchList = React.lazy(() => import('../SearchList'));
const HoDivisionPage = React.lazy(() => import('../HoDivisionPage'));

function componentShowRender(copm) {
    switch (copm) {
        case "HomePage":
            return HomePage;
        case "AboutUs":
            return AboutUs;
        case "Services":
            return Services;
        case "Announcements":
            return Announcements;
        case "EtenderList":
            return EtenderList;
        case "HoDivisionPage":
            return HoDivisionPage;
        case "TeamPage":
            return TeamPage;
        case "Portfolio":
            return Portfolio;
        case "Gallery":
            return Gallery;
        case "BlogLists":
            return BlogLists;
        case "ContactUsPage":
            return ContactUsPage;
        case "Page":
            return Page;
        case "PressRelease":
            return PressRelease;
        case "SalesTurnOver":
            return SalesTurnOver;
        case "GalleryVideo":
            return GalleryVideo;
        case "GalleryVideoList":
            return GalleryVideoList;
        case "OutletStrength":
            return OutletStrength;
        case "AnnoucementList":
            return AnnoucementList;
        case "CareerList":
            return CareerList;
        default:
            return HomePage;
    }
}

export default function Routes(props) {
    return (
<Suspense fallback={<></>}>
        <Switch>
            <PrivateRoute exact fixHeader={true} path="/" component={HomePage} />

            {props.menus !== undefined
                ? props.menus.map((menu, i) => {
                    return (
                        <PrivateRoute
                            key={i}
                            fixHeader={menu.component === "HomePage" ? true : true}
                            exact
                            path={`/${menu.slug}`}
                            component={componentShowRender(menu.component)}
                        />
                    );
                })
                : ""}
            {props.menus !== undefined
                ? props.menus.map((menu, i) => {
                    return menu.submenu ? menu.submenu.map((subitem, j) => {
                        return (
                            <PrivateRoute
                                key={i + j}
                                fixHeader={menu.component === "HomePage" ? true : true}
                                exact
                                path={`/${subitem.slug}`}
                                component={componentShowRender(subitem.component)}
                            />
                        )
                    }) : ""

                })
                : ""}

            <PrivateRoute
                path="/announcement-details/:id"
                fixHeader={true}
                component={Announcements}
            />
            <PrivateRoute
                path="/news-details/:id"
                fixHeader={true}
                component={NewsDetails}
            />
            <PrivateRoute
                path="/our-products"
                fixHeader={true}
                component={ProductList}
            />
            <PrivateRoute
                path="/store-details/:id"
                fixHeader={true}
                component={StoreList}
            />
            <PrivateRoute
                path="/e-tenders"
                exact
                fixHeader={true}
                component={EtenderList}
            />
            <PrivateRoute
                path="/careers"
                fixHeader={true}
                component={CareerList}
            />
            <PrivateRoute
                path="/site-map"
                fixHeader={true}
                component={SiteMapList}
            />
            <PrivateRoute
                path="/search/:key"
                fixHeader={true}
                component={SearchList}
            />
            <PrivateRoute
                path="/ho-divisions"
                fixHeader={true}
                component={HoDivisionPage}
            />

            <PrivateRoute path="/contact-us" fixHeader={true} component={ContactUsPage} />
            <PrivateRoute path="/portfolio-single/:id" component={PortfolioSingle} />
            <PrivateRoute exact path="/blog/:id" component={BlogLists} />
            <PrivateRoute path="/blog-details/:id" component={BlogSingle} />
            <PrivateRoute path="/gallery" component={Gallery} />
            <PrivateRoute path="/cms/:slug" fixHeader={true} component={Page} />
            <PrivateRoute path="/gallery-list/:id" fixHeader={true} component={GalleryList} />
            <PrivateRoute path="/press-release" fixHeader={true} component={PressRelease} />
            <PrivateRoute path="/press-release-details/:id" fixHeader={true} component={PressReleaseDetails} />
            <PrivateRoute path="/turn-over" fixHeader={true} component={SalesTurnOver} />
            <PrivateRoute path="/gallery-video" fixHeader={true} component={GalleryVideo} />
            <PrivateRoute path="/gallery-video-list/:id" fixHeader={true} component={GalleryVideoList} />
            <PrivateRoute path="/outlet-strength" fixHeader={true} component={OutletStrength} />
            <PrivateRoute path="/announcement-list" fixHeader={true} component={AnnoucementList} />
        </Switch>
  </Suspense>
    );
}