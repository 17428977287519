import {LOAD_ANNOUNCEMENT_SUCCESSFUL} from "../actions/type";

const init = {};

const announcementReducer = (state = init, action) => {
    switch (action.type) {
        case LOAD_ANNOUNCEMENT_SUCCESSFUL: {
            return action.data;
        }
        default:
            return state;
    }
};

export default announcementReducer;